import React from 'react';
import {
  ComponentPreview,
  DontDo,
  DontDoItem,
  IconItemList,
  IconItem,
  Link,
  PageHero,
  PageNavigation,
  PageNavigationLink,
  PageWithSubNav,
  Paragraph,
  PlatformTable,
  PlatformTableRow,
  Section,
  SectionSubhead,
} from 'docComponents';
import { Icon } from 'hudl-uniform-ui-components';
import pageHeroData from '../../../data/pages/guidelines.yml';
import styles from './styles/iconography.module.scss';

const IndexPage = () => {
  return (
    <PageWithSubNav
      pageType="design"
      title="Iconography"
      designCode
      subnav="guidelines">
      <PageHero heroData={pageHeroData} tierTwo="Iconography" />

      <PageNavigation>
        <PageNavigationLink>Style</PageNavigationLink>
        <PageNavigationLink>Metaphor</PageNavigationLink>
        <PageNavigationLink>Color</PageNavigationLink>
        <PageNavigationLink>Usage</PageNavigationLink>
        <PageNavigationLink>Platform</PageNavigationLink>
      </PageNavigation>

      <Section title="Style">
        <Paragraph>
          Our iconography style relies on our personality traits: <em>smart</em>{' '}
          and <em>reliable</em>.
        </Paragraph>
        <Paragraph>
          This icon style can be described as “approachable”. Hard shapes are
          softened for personality but never gratuitously rounded or bubbly to
          feel overly playful. Put another way: sports are fun, but we know
          athletes and coaches take their sport seriously.
        </Paragraph>
      </Section>

      <Section title="Metaphor">
        <Paragraph className="uni-pad--one--btm">
          Supporting our predictable design principle, we strive to use industry
          standard metaphors.
        </Paragraph>

        <IconItemList>
          <IconItem reactName="settings" displayName="Settings" />
          <IconItem reactName="help" displayName="Help" />
          <IconItem reactName="search" displayName="Search" />
          <IconItem reactName="edit" displayName="Edit" />
          <IconItem reactName="share" displayName="Share" />
          <IconItem reactName="play" displayName="Play" />
          <IconItem reactName="add" displayName="Add" />
          <IconItem reactName="user" displayName="User" />
        </IconItemList>

        <Paragraph className="uni-pad--two--top">
          For unique features where an industry standard has not yet been
          established, clarity is the guiding principle.
        </Paragraph>
      </Section>

      <Section title="Color">
        <Paragraph>
          Our standard icons are glyphs and should be filled with a single solid
          color, presented without shadows.
        </Paragraph>

        <ComponentPreview name="IconColor" layout="row">
          <Icon
            type="messages"
            size="large"
            className={styles.guidelinesIcons + ' ' + styles.colorContrast}
          />
          <Icon
            type="messages"
            size="large"
            className={styles.guidelinesIcons + ' ' + styles.colorNonessential}
          />
          <Icon
            type="messages"
            size="large"
            className={styles.guidelinesIcons + ' ' + styles.colorUtilityAction}
          />
          <Icon
            type="messages"
            size="large"
            className={
              styles.guidelinesIcons + ' ' + styles.colorUtilityConfirmation
            }
          />
          <Icon
            type="messages"
            size="large"
            className={
              styles.guidelinesIcons + ' ' + styles.colorUtilityWarning
            }
          />
          <Icon
            type="messages"
            size="large"
            className={
              styles.guidelinesIcons + ' ' + styles.colorUtilityCritical
            }
          />
        </ComponentPreview>
      </Section>

      <Section title="Usage">
        <Paragraph>
          Our{' '}
          <Link href="/components/icon" isDesignCodeLink>
            icon
          </Link>{' '}
          component shows how categorizing icons guide how they should be used
          within an interface.
        </Paragraph>

        <SectionSubhead>Style</SectionSubhead>
        <Paragraph>
          It’s always best to prefer clarity over cleverness and style.
        </Paragraph>
        <DontDo gridLayout="2">
          <DontDoItem
            type="dont"
            text="be too playful or abrasive."
            img="iconography-style-dont"
          />
          <DontDoItem
            type="do"
            text="put clear meaning over style."
            img="iconography-style-do"
          />
        </DontDo>

        <SectionSubhead>Metaphor</SectionSubhead>
        <Paragraph>
          Icons without an established metaphor should be supported with text.
          Always use the correct icon with its intended meaning.
        </Paragraph>
        <DontDo gridLayout="2">
          <DontDoItem
            type="dont"
            text="use an existing metaphor to define a new icon."
            img="iconography-metaphor-dont"
          />
          <DontDoItem
            type="do"
            text="use the right icon for the right metaphor."
            img="iconography-metaphor-do"
          />
        </DontDo>
      </Section>

      <Section title="Platform">
        <PlatformTable footerMessage="No guidelines exist for Windows (yet).">
          <PlatformTableRow platform="web" />
          <PlatformTableRow platform="apple" />
          <PlatformTableRow platform="android" />
        </PlatformTable>
      </Section>
    </PageWithSubNav>
  );
};

export default IndexPage;
